<template>
	<div class="s1 p-card ">
		<div class="flex p-3 fs-16 pointer"
		     @click="myShow=true">
			<div class="flex-1 b">推品时间段</div>
			<div><b>{{ startTime }}-{{ endTime }}</b></div>
			<div class="pl-1">
				<div class="p-icon-arrow"
				     style="position:relative;top: -.01rem"/>
			</div>
		</div>
		<van-popup v-model:show="myShow" position="bottom" class="time-popup" :style="{background: 'none'}"
		           @opened="opened">
			<div class="main">
				<van-picker show-toolbar title="开启时间" :columns="columns" @cancel="myShow=false" @confirm="handleConfirm"/>
			</div>
		</van-popup>
	</div>

</template>

<script>
import { computed, onMounted, reactive, ref, toRefs } from 'vue'

export default {
	name: 'TimePicker',
	props: {
		show: {
			type: Boolean,
			default: false
		},
		startTime: {
			type: String,
			default: '00:00'
		},
		endTime: {
			type: String,
			default: '23:59'
		}
	},
	emits: ['update:show', 'confirm'],
	setup(props, { slots, emit }) {
		const data = reactive({
			columns: []
		})
		const myShow = ref(false)
		const TheAddZero = function (n) {
			return n < 10 ? '0' + n : n
		}
		const createPicker = () => {
			const h = []
			const m = []
			let startHour = 8
			let startMinute = 30
			let endHour = 8
			let endMinute = 30
			for (let i = 0; i < 24; i++) {
				h.push({
					text: TheAddZero(i) + '时',
					value: i
				})
			}
			for (let i = 0; i < 60; i++) {
				m.push({
					text: TheAddZero(i) + '分',
					value: i
				})
			}
			data.columns = [
				{
					values: h,
					defaultIndex: startHour
				},
				{
					values: m,
					defaultIndex: startMinute
				},
				{
					values: ['至'],
					defaultIndex: 0
				},
				{
					values: h,
					defaultIndex: endHour
				},
				{
					values: m,
					defaultIndex: endMinute
				}
			]
		}

		const handleConfirm = (val) => {
			// todo 存在连续点击
			const startTime = TheAddZero(val[0].value) + ':' + TheAddZero(val[1].value)
			const endTime = TheAddZero(val[3].value) + ':' + TheAddZero(val[4].value)
			emit('confirm', startTime, endTime)
			myShow.value = false
		}
		const opened = () => {
			// 重置
			const t1 = props.startTime.split(':')
			const t2 = props.endTime.split(':')
			let startHour = Number(t1[0])
			let startMinute = Number(t1[1])
			let endHour = Number(t2[0])
			let endMinute = Number(t2[1])
			data.columns[0].defaultIndex = startHour
			data.columns[1].defaultIndex = startMinute
			data.columns[3].defaultIndex = endHour
			data.columns[4].defaultIndex = endMinute
		}
		onMounted(() => {
			createPicker()
		})
		return {
			myShow,
			...toRefs(data),
			handleConfirm,
			opened
		}
	}
}
</script>

<style lang="scss" scoped>

.time-popup {
	.main {
		margin: auto;
		border-radius: px(4) px(4) 0 0;
		max-width: $max-width;
		overflow: hidden;

		:deep .van-picker__confirm {
			color: $color-primary;
		}
	}
}
</style>
